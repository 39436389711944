var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getActiveCompany)?_c('div',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-lg"},[_c('img',{staticClass:"avatar-img rounded",attrs:{"src":_vm.getActiveCompany.SmallImageUrl ||
                  '/images/zesec-placeholder.png',"alt":_vm.getActiveCompany.Name}})])]),_c('div',{staticClass:"col ml-n3 ml-md-n2"},[_c('h6',{staticClass:"header-pretitle"},[_vm._v(" "+_vm._s(_vm.$t("companies.company"))+" ")]),_c('h1',{staticClass:"header-title"},[_vm._v(" "+_vm._s(_vm.companyName(_vm.getActiveCompany.Name))+" ")])]),(_vm.roleFlag && (_vm.userRole != 'LocationManager' || _vm.userRole != 'KeyManager'))?_c('div',{staticClass:"col col-md-auto mt-3 mt-md-0"},[_c('b-dropdown',{staticClass:"float-right",attrs:{"text":_vm.$t('buttons.manage'),"right":"","variant":"primary","toggle-tag":"a"}},[_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.editCompany()}}},[_vm._v(_vm._s(_vm.$t("buttons.edit")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",attrs:{"disabled":_vm.userRole == 'CompanyManager'},on:{"click":function($event){return _vm.deleteCompany()}}},[_vm._v(_vm._s(_vm.$t("buttons.delete")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.companyImage(_vm.getActiveCompany)}}},[_vm._v(_vm._s(_vm.$t("companies.image")))])],1)],1):_vm._e()])])])]),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t("locations.title"))+" ")]),_c('form',{staticClass:"mr-3"},[_c('Select2',{attrs:{"options":_vm.perPageOptions,"settings":{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }},on:{"change":_vm.paginatedLocationList},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('form',{staticClass:"mr-3"},[_c('Select2',{attrs:{"options":_vm.sortOrderOptions,"settings":{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }},model:{value:(_vm.sortOrder),callback:function ($$v) {_vm.sortOrder=$$v},expression:"sortOrder"}})],1),(_vm.getActiveCompany)?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.createLocation.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("locations.create")))]):_vm._e()],1),_c('div',{staticClass:"card-header"},[_c('form',[_c('div',{staticClass:"input-group input-group-flush input-group-merge"},[_c('b-form-input',{staticClass:"form-control form-control-prepended search",attrs:{"placeholder":_vm.$t('common.search'),"type":"search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"fe fe-search"})])])],1)])]),_c('div',{staticClass:"card-body"},[_c('b-pagination',{staticClass:"pb-2",attrs:{"total-rows":_vm.locationsList.length,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('zs-list-transition',{attrs:{"showLoader":_vm.showLoader},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.paginatedLocationList),function(location){return _c('location-list-item',{key:location.Id,attrs:{"location":location}})})},proxy:true},{key:"empty",fn:function(){return [_c('empty-list',{attrs:{"title":_vm.$t('locations.no_locations')}})]},proxy:true}],null,false,313278858)}),_c('b-pagination',{staticClass:"pb-2",attrs:{"total-rows":_vm.locationsList.length,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])],1)],1),_c('zs-company-delete-modal'),_c('zs-image-upload-modal',{on:{"imageUploaded":_vm.updateCompanyImage}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }