<template>
  <div v-if="getActiveCompany">
    <div class="header">
      <div class="container-fluid">
        <!-- Body -->
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar avatar-lg">
                <img
                  :src="
                    getActiveCompany.SmallImageUrl ||
                    '/images/zesec-placeholder.png'
                  "
                  :alt="getActiveCompany.Name"
                  class="avatar-img rounded"
                />
              </div>
            </div>
            <div class="col ml-n3 ml-md-n2">
              <h6 class="header-pretitle">
                {{ $t("companies.company") }}
              </h6>
              <!--  :company="company" -->
              <h1 class="header-title">
                {{ companyName(getActiveCompany.Name) }}
              </h1>
            </div>
            <div
              class="col col-md-auto mt-3 mt-md-0"
              v-if="roleFlag && (userRole != 'LocationManager' || userRole != 'KeyManager')"
            >
              <b-dropdown
                :text="$t('buttons.manage')"
                right
                variant="primary"
                toggle-tag="a"
                class="float-right"
              >
                <b-dropdown-item
                  class="text-left text-truncate"
                  @click="editCompany()"
                  >{{ $t("buttons.edit") }}</b-dropdown-item
                >
                <b-dropdown-item
                  class="text-left text-truncate"
                  :disabled="userRole == 'CompanyManager'"
                  @click="deleteCompany()"
                  >{{ $t("buttons.delete") }}</b-dropdown-item
                >
                <b-dropdown-item
                  class="text-left text-truncate"
                  @click="companyImage(getActiveCompany)"
                  >{{ $t("companies.image") }}</b-dropdown-item
                >
              </b-dropdown>
            </div>

          </div>
        </div>
      </div>
    </div>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">
                {{ $t("locations.title") }}
              </h4>
              <form class="mr-3">
                  <Select2
                    v-model="perPage"
                    :options="perPageOptions"
                    :settings="{
                      minimumResultsForSearch: -1,
                      containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                      dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                    }"
                    @change="paginatedLocationList"
                  />
              </form>

              <!-- Select -->
              <form class="mr-3">
                <Select2
                  v-model="sortOrder"
                  :options="sortOrderOptions"
                  :settings="{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }"
                />
              </form>

              <!-- Button -->
              <b-button
                variant="primary"
                size="sm"
                v-if="getActiveCompany"
                @click.stop.prevent="createLocation"
                >{{ $t("locations.create") }}</b-button
              >

            </div>
            <div class="card-header">
              <!-- Form -->
              <form>
                <div class="input-group input-group-flush input-group-merge">
                  <!-- Input -->

                  <b-form-input
                    :placeholder="$t('common.search')"
                    v-model="filter"
                    class="form-control form-control-prepended search"
                    type="search"
                  ></b-form-input>

                  <!-- Prepend -->
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <span class="fe fe-search"></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body">
              <b-pagination
                v-model="currentPage"
                :total-rows="locationsList.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
              <zs-list-transition :showLoader="showLoader">
                <template v-slot:content>
                  <location-list-item
                    :location="location"
                    v-for="location in paginatedLocationList"
                    v-bind:key="location.Id"
                  />
                </template>
                <template v-slot:empty>
                  <empty-list :title="$t('locations.no_locations')" />
                </template>
              </zs-list-transition>
              <b-pagination
                v-model="currentPage"
                :total-rows="locationsList.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <zs-company-delete-modal />
    <zs-image-upload-modal @imageUploaded="updateCompanyImage" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListTransition from "@/components/common/ListTransition";
import EmptyList from "@/components/common/EmptyList";
import LocationListItem from "@/components/location/LocationListItem";
import DeleteCompanyModal from "@/components/modals/DeleteCompany";
import ImageUploadModal from "@/components/modals/ImageUpload";
import Select2 from "@/components/common/Select2";

import {
  LOCATION_CLEAR_ACTIVE,
  LOCATIONS_GET_COMPANY_LOCATIONS,
  LOCATIONS_CLEAR,
} from "@/store/types/locations";
import {
  COMPANIES_GET,
  COMPANY_GET,
  COMPANY_PATCH,
} from "@/store/types/companies";
import {
  M_COMPANY_FORM,
  M_LOCATION_FORM,
  M_LOCATION_DELETE,
  M_COMPANY_DELETE,
  M_IMAGE_UPLOAD,
} from "@/components/modals/types";

import { IMAGE_TYPE_COMPANY } from "@/handlers/const";
import modalMixin from "@/mixins/modalMixin";
import {
    LOCATION_PATCH,
    LOCATION_GET,
} from "@/store/types/locations";
import { EventBus } from "@/main.js"

export default {
  mixins: [modalMixin],
  data() {
    return {
      filter: "",
      sortOrder: 1,
      sortOrderOptions: [
        { id: 1, text: "Asc"},
        { id: 2, text: "Desc"},
      ],
      showLoader: true,
      isMobile: false,
      width: window.innerWidth,
      height: window.innerHeight,
      location:[],
      perPage: 20,
      currentPage: 1,
      perPageOptions: [
        { id: 10, text: "10 " + this.$t("menu.per_page") },
        { id: 20, text: "20 " + this.$t("menu.per_page") },
        { id: 50, text: "50 " + this.$t("menu.per_page") },
      ],
      userRole: "",
      roleFlag: true,
    };
  },
  computed: {
    ...mapGetters([
      "getActiveCompany",
      "getCompanyLocations",
      "getUserCompanies",
      "getUserRoles"
    ]),
    locationsList() {
      return this.getCompanyLocations
        .filter((item) =>
          item.Name.toLowerCase().includes(this.filter.toLowerCase()) || item.Address.toLowerCase().includes(this.filter.toLowerCase())
        )
        .sort((i1, i2) => {
          if (this.sortOrder == 1) {
            return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
          } else {
            return i1.Name.toUpperCase() < i2.Name.toUpperCase() ? 1 : -1;
          }
        });
    },
    paginatedLocationList(){
      return this.locationsList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    }
  },
  created() {
    this.$store.commit(LOCATION_CLEAR_ACTIVE);
    this.$store.dispatch(COMPANY_GET, this.$route.params.companyId);
    this.$store
      .dispatch(LOCATIONS_GET_COMPANY_LOCATIONS, this.$route.params.companyId)
      .then(() => (this.showLoader = false));
    this.getLoggedInUserRoles();
    // || this.userRole == "LocationManager" || this.userRole == "KeyManager"
    if( this.userRole == "CompanyOwner" || this.userRole == "CompanyManager" ){
      this.roleFlag = true
    }else{
      this.roleFlag = false
    }
    window.addEventListener("resize", this.onResize);
    window.addEventListener("onload", this.onLoad);
  },
  destroyed() {
    this.$store.commit(LOCATIONS_CLEAR);
    window.removeEventListener("resize", this.onResize);
    this.isMobile = true;
  },
  methods: {
    getLoggedInUserRoles() {
            this.getUserRoles.filter((item) => item.CompanyId == this.$route.params.companyId)
            .map((item) => {
                if(item.Name == "CompanyOwner" || item.Name == "CompanyManager" || item.Name == "LocationManager" || item.Name == "KeyManager"){
                  this.$session.set("userRole", item.Name)
                  EventBus.$emit("userRole", item.Name);
                  return this.userRole = item.Name;
                }else{
                    return
                }
            });

    },
    createLocation() {
      this.showModal(null, M_LOCATION_FORM);
    },
    editLocation(location) {
      this.showModal({ location: location }, M_LOCATION_FORM);
    },
    deleteLocation(location) {
      this.showModal({ location: location }, M_LOCATION_DELETE);
    },
    editCompany() {
      this.showModal({ company: this.getActiveCompany }, M_COMPANY_FORM);
    },
    deleteCompany() {
      this.showModal({ company: this.getActiveCompany }, M_COMPANY_DELETE);
    },
    companyImage() {
      this.company = this.getActiveCompany;
      this.showModal(
                    {
                        image: {
                            url:
                            this.company.MediumImageUrl ||
                            "/images/zesec-placeholder.png",
                            type: IMAGE_TYPE_COMPANY,
                        },
                    },
                    M_IMAGE_UPLOAD
                );
      /* this.showModal(
        {
          image: {
            url:
              this.getActiveCompany.MediumImageUrl ||
              "/images/zesec-placeholder.png",
            type: IMAGE_TYPE_COMPANY,
          },
        },
        M_IMAGE_UPLOAD
      ); */
    },
    updateCompanyImage(response) {
      if(response.Name.includes("location_avatar")){
          let lid = this.$session.get("locationId")
          this.$store.dispatch(LOCATION_GET, lid).then((responseLocation) => {
            this.location = responseLocation
            this.$store
                  .dispatch(LOCATION_PATCH, {
                      ...this.location,
                      ...{ ImageId: response.Id },
                  })
                  .then(() => {
                      this.$store.dispatch(LOCATION_GET,lid);
                      this.$store.dispatch(LOCATIONS_GET_COMPANY_LOCATIONS, this.$route.params.companyId)
                  })
                  .catch((error) => {});
          });
      }else if(response.Name.includes("company_avatar")){
        this.$store
          .dispatch(COMPANY_PATCH, {
            ...this.getActiveCompany,
            ...{ ImageId: response.Id },
          })
          .then(() => {
            this.$store.dispatch(COMPANY_GET, this.getActiveCompany.Id);
          })
          .catch((error) => {});
      }
    },
    onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(this.width < 1200){
                this.isMobile = true
            }else{
                this.isMobile = false
            }
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        companyName(name){
            name = this.getActiveCompany.Name
            let wordCounterwithSpace = 0;
            let wordCounterwithoutSpace = 0;
            for (let i = 0; i < name.length; i++) {
                if (name[i] == ' ') {
                    wordCounterwithSpace++;
                    continue;
                } else {
                    wordCounterwithoutSpace++;
                    wordCounterwithSpace++;
                }
            }
            if(this.isMobile && (wordCounterwithSpace > 20 || wordCounterwithoutSpace >20)){
              name = name.slice(0, 10).concat('...');
            }else{
              name = this.getActiveCompany.Name;
            }
            return name
        },
  },
  beforeRouteUpdate(to, from, next) {
    this.showLoader = true;
    this.$store.dispatch(COMPANY_GET, to.params.companyId);
    this.$store
      .dispatch(LOCATIONS_GET_COMPANY_LOCATIONS, to.params.companyId)
      .then(() => (this.showLoader = false));
    next();
  },
  components: {
    LocationListItem,
    EmptyList,
    "zs-list-transition": ListTransition,
    "zs-company-delete-modal": DeleteCompanyModal,
    "zs-image-upload-modal": ImageUploadModal,
    Select2,
  },
};
</script>
